@import "~leaflet/dist/leaflet.css";

html,
body,
.App {
  height: 100vh;
  width: 100vw;
}

body {
  height: 100%
}

.no-border-grid {
  border: none !important;
  outline: none !important;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidden-print {
  display: none;
}

@media print {
  .hidden-print {
    display: flex;
  }
}
